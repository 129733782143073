import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'login',
        component: () => import('../views/Login')
    },
    {
        path: '/home',
        name: 'home',
        component: () => import('../views/pages/Home'),
        
    },
    {
        path: '/contrato',
        name: 'contrato',
        component: () => import('../views/pages/Contrato'),
        
    },
    {
        path: '/menu',
        name: 'menu',
        component: () => import('../views/pages/Menu'),
        
    },
    {
        path: '/mensalidade',
        name: 'mensalidade',
        component: () => import('../views/pages/Mensalidade')
    },
    
    {
        path: '/endereco',
        name: 'endereco',
        component: () => import('../views/pages/Cadastro')
    },
    {
        path: '/temsaude',
        name: 'temsaude',
        component: () => import('../views/pages/Temsaude')
    },
    {
        path: '/lecupon',
        name: 'lecupon',
        component: () => import('../views/pages/Lecupon')
    },
    {
        path: '/empresa',
        name: 'empresa',
        component: () => import('../views/pages/Empresa')
    },
    {
        path: '/faleconosco',
        name: 'faleconosco',
        component: () => import('../views/pages/FaleConosco')
    },
    {
        path: '/velorios',
        name: 'velorios',
        component: () => import('../views/pages/Velorios')

    },
    {
        path: '/despedida',
        name: 'despedida',
        component: () => import('../views/pages/Despedida')

    }
]

export default new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes
})
